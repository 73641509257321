var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "authorize" },
    [
      _c(
        "Row",
        {
          staticStyle: { height: "100%" },
          attrs: { type: "flex", justify: "center", align: "middle" },
          nativeOn: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "Col",
            {
              staticClass: "content",
              style: { width: _vm.userInfo ? "450px" : "390px" },
            },
            [
              _c("div", [
                _c("div", { staticClass: "logos-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "logos" },
                    [
                      _c("Avatar", {
                        staticClass: "top site-logo",
                        style: { background: "#fff" },
                        attrs: { src: _vm.site.logo, size: "96" },
                      }),
                      _c("Icon", {
                        staticClass: "top",
                        attrs: {
                          type: "ios-checkmark-circle",
                          color: "#52c41a",
                          size: "32",
                        },
                      }),
                      _c("Avatar", {
                        staticClass: "top",
                        style: { background: "#f1f2f5" },
                        attrs: {
                          src: require("@/assets/logo.png"),
                          size: "96",
                        },
                      }),
                      _c("div", { staticClass: "line" }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "auth-title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("authorize")) + " " + _vm._s(_vm.site.name)
                    ),
                  ]),
                ]),
                !_vm.error && !_vm.authLoading && !_vm.userInfo
                  ? _c(
                      "div",
                      [
                        _c(
                          "Tabs",
                          { attrs: { value: "1" } },
                          [
                            _c(
                              "TabPane",
                              {
                                attrs: {
                                  label: _vm.$t("sso"),
                                  name: "1",
                                  icon: "md-people",
                                },
                              },
                              [
                                _c(
                                  "Form",
                                  {
                                    ref: "loginForm",
                                    staticClass: "form",
                                    attrs: {
                                      model: _vm.form,
                                      rules: _vm.rules,
                                    },
                                  },
                                  [
                                    _c(
                                      "FormItem",
                                      { attrs: { prop: "username" } },
                                      [
                                        _c("Input", {
                                          attrs: {
                                            prefix: "ios-contact",
                                            size: "large",
                                            clearable: "",
                                            placeholder: "账号/邮箱/手机号",
                                            autocomplete: "off",
                                          },
                                          model: {
                                            value: _vm.form.username,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "username",
                                                $$v
                                              )
                                            },
                                            expression: "form.username",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "FormItem",
                                      { attrs: { prop: "password" } },
                                      [
                                        _c("Input", {
                                          attrs: {
                                            type: "password",
                                            prefix: "ios-lock",
                                            size: "large",
                                            clearable: "",
                                            placeholder: "请输入密码",
                                            autocomplete: "off",
                                          },
                                          model: {
                                            value: _vm.form.password,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression: "form.password",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "FormItem",
                                      { attrs: { prop: "code" } },
                                      [
                                        _c(
                                          "Row",
                                          {
                                            staticStyle: {
                                              "align-items": "center",
                                              overflow: "hidden",
                                            },
                                            attrs: {
                                              type: "flex",
                                              justify: "space-between",
                                            },
                                          },
                                          [
                                            _c("Input", {
                                              staticStyle: { width: "67%" },
                                              attrs: {
                                                size: "large",
                                                clearable: "",
                                                placeholder: "请输入图片验证码",
                                                maxlength: 10,
                                              },
                                              model: {
                                                value: _vm.form.code,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "code",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.code",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "code-image",
                                                staticStyle: {
                                                  position: "relative",
                                                  "font-size": "12px",
                                                },
                                              },
                                              [
                                                _vm.loadingCaptcha
                                                  ? _c("Spin", {
                                                      attrs: { fix: "" },
                                                    })
                                                  : _vm._e(),
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "110px",
                                                    cursor: "pointer",
                                                    display: "block",
                                                  },
                                                  attrs: {
                                                    src: _vm.captchaImg,
                                                    alt: "加载验证码失败",
                                                  },
                                                  on: {
                                                    click: _vm.getCaptchaImg,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "Row",
                          [
                            _c(
                              "Button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "large",
                                  loading: _vm.loading,
                                  long: "",
                                },
                                on: { click: _vm.submit },
                              },
                              [
                                !_vm.loading
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("authorizeAndSignin"))
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.$t("authorizing"))),
                                    ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "Row",
                          {
                            staticClass: "other-thing",
                            attrs: { type: "flex", justify: "space-between" },
                          },
                          [
                            _c(
                              "router-link",
                              { staticClass: "back", attrs: { to: "/reset" } },
                              [_vm._v(_vm._s(_vm.$t("forgetPass")))]
                            ),
                            _c(
                              "router-link",
                              { staticClass: "back", attrs: { to: "/regist" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("registerNow")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.error && !_vm.authLoading && _vm.userInfo
                  ? _c(
                      "div",
                      [
                        !_vm.error
                          ? _c(
                              "Card",
                              { attrs: { "dis-hover": "", padding: 0 } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "auth-card" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "auth-info-wrap" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "auth-detail",
                                            staticStyle: {
                                              "margin-bottom": "25px",
                                            },
                                          },
                                          [
                                            _c("Avatar", {
                                              staticClass: "auth-pic",
                                              style: { background: "#fff" },
                                              attrs: {
                                                src: _vm.userInfo.avatar,
                                                size: "40",
                                              },
                                            }),
                                            _c("div", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "auth-detail-href",
                                                  attrs: {
                                                    href: _vm.site.homeUri,
                                                    target: "_blank",
                                                  },
                                                },
                                                [_vm._v(_vm._s(_vm.site.name))]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "auth-detail-sub-title",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("wants")) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "auth-detail-strong",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.userInfo.username
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("wants2")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "auth-detail",
                                            staticStyle: {
                                              "margin-bottom": "15px",
                                            },
                                          },
                                          [
                                            _c("Icon", {
                                              staticClass: "auth-pic",
                                              attrs: {
                                                type: "md-person",
                                                color: "#6a737d",
                                                size: "40",
                                              },
                                            }),
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "auth-detail-title",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("authAquire")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "auth-detail-sub-title",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("aquireInfo")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "Row",
                                      { staticClass: "button-confirm" },
                                      [
                                        _c(
                                          "Button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "large",
                                              loading: _vm.loading,
                                              long: "",
                                            },
                                            on: { click: _vm.confirm },
                                          },
                                          [
                                            !_vm.loading
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("authorize"))
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("authorizing")
                                                    )
                                                  ),
                                                ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("Row", { staticClass: "to-wrap" }, [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.$t("afterAuth"))),
                                      ]),
                                      _c("div", { staticClass: "to-strong" }, [
                                        _vm._v(_vm._s(_vm.site.homeUri)),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.error && !_vm.authLoading
                  ? _c(
                      "div",
                      { staticStyle: { "margin-top": "15vh" } },
                      [
                        _c(
                          "Alert",
                          { attrs: { type: "error", "show-icon": "" } },
                          [
                            _vm._v(" " + _vm._s(_vm.title) + " "),
                            _c(
                              "span",
                              { attrs: { slot: "desc" }, slot: "desc" },
                              [_vm._v(_vm._s(_vm.msg))]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.authLoading ? _c("div", [_c("RectLoading")], 1) : _vm._e(),
              ]),
              _c("Footer"),
            ],
            1
          ),
          _c("LangSwitch"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }